import React, { useEffect, useMemo, useState } from 'react';
import { RouteProps } from 'react-router-dom';
// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import Content from 'components/sidebar/components/Content';
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import GridIcon from 'assets/svg/GridIcon';
import TableIcon from 'assets/svg/TableIcon';
import { zoneService } from 'services/zoneService';

// Assets
import { IoMenuOutline } from 'react-icons/io5';

// Add this import at the top of the file
import { FlexibleRoutesType, CustomRouteProps } from '../../types/types';
import ZoneListPage from 'views/admin/main/zones/ZoneListPage';
import { useAuth } from 'contexts/AuthContext';
import { useSelector , useDispatch} from 'react-redux';
import { addZones } from 'store/slices/zoneSlice';



// Add this type definition
// type CustomRouteProps = RouteProps & { setCurrentZoneName: (name: string) => void };


function Sidebar(props: {
  routes: FlexibleRoutesType[] | ((props: CustomRouteProps) => FlexibleRoutesType[]);
  setCurrentZoneName: (name: string) => void;
  mini: boolean;
  hovered: boolean;
  setHovered: (hovered: boolean) => void;
  [x: string]: any
}) {
  const { routes: routesProp, currentZoneName, setCurrentZoneName, mini, hovered, setHovered } = props;
  const { user } = useAuth();

  const [dynamicRoutes , setDynamicRoutes] = useState<FlexibleRoutesType[]>([]);
  const companyId = useAuth().user.companyId;
  const userEmail = useAuth().user.email;
   // @ts-ignore
  const state = useSelector(s => s.zone.allZones);
  const dispatch = useDispatch();

  // Filter routes based on user role
  const filteredRoutes = useMemo(() => {
    const routesArray = typeof routesProp === 'function' 
      ? routesProp({ currentZoneName, setCurrentZoneName }) 
      : routesProp;

    return routesArray.filter(route => {
      if (!route.role) return true;
      if (Array.isArray(route.role)) {
        return route.role.includes(user?.role);
      }
      return route.role === user?.role;
    });
  }, [routesProp, currentZoneName, setCurrentZoneName, user?.role]);

  const userType = useAuth().user.role;



  useEffect(() => {
    const fetchZones = async () => {
      try {
        const zones = await zoneService.getAllZones(companyId, userType, userEmail);
        if (zones && zones.length) {
          // Sort zones by position before setting them
          const sortedZones = [...zones].sort((a, b) => 
            (a.position ?? Number.MAX_VALUE) - (b.position ?? Number.MAX_VALUE)
          );
          setDynamicRoutes(sortedZones);
          dispatch(addZones(sortedZones));
        }
      } catch (error) {
        console.error('Error fetching zones:', error);
      }
    };
    fetchZones();
  }, [companyId, userType, userEmail, dispatch]);

  const configuredRoutes: FlexibleRoutesType[] = useMemo(() => {
  
    const configuredRoutes: FlexibleRoutesType[] = (state || []).map((item: any) => ({
      name: item.name,
      path: `/zones/${item._id}/list?type=${item.type}`,
      layout: '/admin',
      hidden: false,
      icon: item.type === "table" ? <TableIcon boxSize={"24px"}/> : <GridIcon boxSize={"24px"}/>,
      component: () => <ZoneListPage setCurrentZoneName={setCurrentZoneName} />,

    }))

    return configuredRoutes;
  }, [state])


  // this is for the rest of the collapses
  let variantChange = '0.2s linear';
  let shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset',
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue('white', 'navy.800');
  let sidebarRadius = '20px';
  let sidebarMargins = '0px';
  // SIDEBAR
  return (
    <Box
      display={{ sm: 'none', xl: 'block' }}
      position="fixed"
      minH="100%"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w={
          mini === false
            ? '285px'
            : mini === true && hovered === true
              ? '285px'
              : '120px'
        }
        ms={{
          sm: '16px',
        }}
        my={{
          sm: '16px',
        }}
        h="calc(100vh - 32px)"
        m={sidebarMargins}
        borderRadius={sidebarRadius}
        minH="100%"
        overflowX="hidden"
        boxShadow={shadow}
      >
        {/* @ts-ignore */}
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
                ? renderView
                : renderViewMini
          }
        >
          <Content mini={mini} hovered={hovered} routes={filteredRoutes.concat(configuredRoutes)} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props: {
  routes: FlexibleRoutesType[];
  [x: string]: any;
}) {
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  let menuColor = useColorModeValue('gray.400', 'white');
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes: routesFunction, mini, hovered, setHovered } = props;

  // let isWindows = navigator.platform.startsWith("Win");
  //  BRAND

  return (
    <Flex display={{ sm: 'flex', xl: 'none' }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="285px"
          maxW="285px"
          ms={{
            sm: '16px',
          }}
          my={{
            sm: '16px',
          }}
          borderRadius="16px"
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            zIndex="3"
            onClick={onClose}
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            {/* @ts-ignore */}
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content mini={mini} hovered={hovered} routes={routesFunction} />

            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PR

export default Sidebar;