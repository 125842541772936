import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Text,
  useColorModeValue,
  Select,
  Button,
  Flex,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { zoneService } from '../../../../services/zoneService';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addZones, renameZone, deleteZone, setActiveZone } from 'store/slices/zoneSlice';
import { MenuDots } from 'components/icons/Icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { EditIcon, DeleteIcon, LockIcon } from '@chakra-ui/icons';
import TableIcon from 'assets/svg/TableIcon';
import GridIcon from 'assets/svg/GridIcon';
import { DndContext, DragEndEvent, DragStartEvent, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandleIcon } from '@chakra-ui/icons';

interface Field {
  id: string;
  type: string;
  label: string;
  appearInTable: boolean;
  required: boolean;
  tabId: string;
  order: number;
  position: string;
}

interface ZoneData {
  _id?: string;
  name: string;
  type: string;
  availableUsers: string[];
  fields: Field[];
  tabs: { id: string; name: string; content: string }[];
  companyId: string;
  position?: number;
}

interface Zone {
  _id: string;
  name: string;
  type: string;
  availableUsers: string[];
  position: number;
}

interface SortableZoneCardProps {
  zone: Zone;
  onRename: (name: string) => void;
  onDelete: (id: string) => void;
  onPermissions: (id: string) => void;
}

const SortableZoneCard: React.FC<SortableZoneCardProps> = ({ zone, onRename, onDelete, onPermissions }) => {
  const dispatch = useDispatch();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: zone._id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  const handleLinkClick = (e: React.MouseEvent) => {
    if (isDragging) {
      e.preventDefault();
      return;
    }
    dispatch(setActiveZone(zone.name));
  };

  return (
    <Card ref={setNodeRef} style={style} p={3}>
      <Flex justify="space-between" align="center" mb={2}>
        <Flex flex={1}>
          <Box {...attributes} {...listeners} style={{ cursor: 'grab', touchAction: 'none', padding: '0 8px' }}>
            <Icon as={DragHandleIcon} color="gray.400" />
          </Box>
          
          <Link 
            to={`/admin/zones/${zone._id}?type=${zone.type}`} 
            style={{ textDecoration: 'none', flex: 1 }} 
            onClick={handleLinkClick}
          >
            <Flex alignItems="center" gap={2}>
              <Icon 
                as={zone.type === 'table' ? TableIcon : GridIcon}
                color={zone.type === 'table' ? 'yellow.500' : 'orange.500'} 
                boxSize={6}
                mt='0px!important'
              />
              <Text 
                fontWeight="600" 
                fontSize="lg" 
                _hover={{ textDecoration: 'underline' }}
                color='gray.700'
              >
                {zone.name}
              </Text>
            </Flex>
          </Link>
        </Flex>

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<MenuDots boxSize={6} />}
            variant='ghost'
            size='sm'
            _hover={{
              bg: "gray.200"
            }} 
          />
          <MenuList className='smallMenuDrop'>
            <MenuItem 
              onClick={() => onRename(zone.name)}
              icon={<Icon as={EditIcon} color="orange.500" />}
            >
              Rename
            </MenuItem>
            <MenuItem 
              onClick={() => onDelete(zone._id)}
              icon={<Icon as={DeleteIcon} color="orange.500" />}
            >
              Delete
            </MenuItem>
            <MenuItem 
              onClick={() => onPermissions(zone._id)}
              icon={<Icon as={LockIcon} color="orange.500" />}
            >
              Permissions
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex flexWrap="wrap" gap={2}>
        {zone.availableUsers.map((user: string, index: number) => (
          <Tag key={index} size="sm" bg="orange.500" color="white">
            <TagLabel>{user}</TagLabel>
          </Tag>
        ))}
      </Flex>
    </Card>
  );
};

export default function Zones({ setCurrentZoneName }: { setCurrentZoneName: (name: string) => void }) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [zoneName, setZoneName] = useState('');
  const [zoneType, setZoneType] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [allZones, setAllZones] = useState<Zone[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const dispatch = useDispatch();
  const toast = useToast();
  const companyId = useAuth()?.user?.companyId;
  const userType = useAuth().user.role;
  const userEmail = useAuth().user.email;
  
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [zoneToDelete, setZoneToDelete] = useState<string | null>(null);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [isRenameAlertOpen, setIsRenameAlertOpen] = useState(false);
  const [zoneToRename, setZoneToRename] = useState<{id: string, name: string} | null>(null);
  const [newZoneName, setNewZoneName] = useState('');

  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [editingZoneId, setEditingZoneId] = useState<string | null>(null);
  const [tempSelectedUsers, setTempSelectedUsers] = useState<string[]>([]);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        await fetchZones();
        await fetchUsers();
        if (mounted) {
          setCurrentZoneName("Zone");
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [setCurrentZoneName]);

  const fetchZones = async () => {
    try {
      const zones = await zoneService.getAllZones(companyId, userType, userEmail);
      if (Array.isArray(zones)) {
        const sortedZones = [...zones].sort((a, b) => a.position - b.position);
        dispatch(addZones(sortedZones));
        setAllZones(sortedZones);
      } else {
        setAllZones([]);
        console.error('Received invalid zones data:', zones);
      }
    } catch (error) {
      console.error('Error fetching zones:', error);
      setAllZones([]);
    }
  };

  const fetchUsers = async () => {
    try {
      const users = await zoneService.getSelectUsers();
      setAllUsers(Array.isArray(users) ? users : []);
    } catch (error: unknown) {
      console.error('Error fetching users:', error);
      setAllUsers([]);
    }
  };

  const handleCreateZone = async () => {
    if (!zoneName.trim() || !zoneType) {
      toast({
        title: "Error",
        description: "Zone name and type are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newZone: Omit<ZoneData, '_id'> = {
      name: zoneName.trim(),
      type: zoneType,
      availableUsers: selectedUsers,
      tabs: [{ id: 'default', name: 'Main', content: '' }],
      fields: [{
        id: 'default-title',
        type: 'Text',
        label: 'Title/Name',
        appearInTable: true,
        required: true,
        tabId: 'default',
        order: 0,
        position: 'left'
      }],
      companyId,
      position: 0
    };

    try {
      const createdZone = await zoneService.createZone(newZone) as ZoneData & { _id: string };
      if (createdZone) {
        const zoneToAdd: Zone = {
          _id: createdZone._id,
          name: createdZone.name,
          type: createdZone.type,
          availableUsers: createdZone.availableUsers,
          position: createdZone.position
        };

        dispatch(addZones(createdZone));
        setAllZones(prevZones => 
          Array.isArray(prevZones) 
            ? [...prevZones, zoneToAdd]
            : [zoneToAdd]
        );
        setZoneName('');
        setZoneType('');
        setSelectedUsers([]);

        toast({
          title: "Zone created",
          description: "New zone successfully created!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error creating zone:', error);
      toast({
        title: "Error",
        description: "Failed to create new zone. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUserSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUser = e.target.value;
    if (selectedUser && Array.isArray(selectedUsers)) {
      if (!selectedUsers.includes(selectedUser)) {
        setSelectedUsers(prev => Array.isArray(prev) ? [...prev, selectedUser] : [selectedUser]);
      }
    }
  };

  const removeUser = (user: string) => {
    if (Array.isArray(selectedUsers)) {
      setSelectedUsers(prev => prev.filter(u => u !== user));
    }
  };

  const handleRename = async (zoneId: string, newName: string) => {
    try {
      const response = await zoneService.renameZone(zoneId, newName);
      if (response) {
        setAllZones(prevZones =>
          prevZones.map(zone =>
            zone._id === zoneId ? { ...zone, name: newName } : zone
          )
        );
        
        dispatch(renameZone({ id: zoneId, newName }));
        setCurrentZoneName(newName);

        toast({
          title: "Success",
          description: "Zone renamed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error renaming zone:', error);
      toast({
        title: "Error",
        description: "Failed to rename zone. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsRenameAlertOpen(false);
      setZoneToRename(null);
      setNewZoneName('');
    }
  };

  const handleDelete = (zoneId: string) => {
    setZoneToDelete(zoneId);
    onOpen();
  };

  const confirmDelete = async () => {
    if (zoneToDelete) {
      try {
        await zoneService.deleteZone(zoneToDelete);
        setAllZones(prevZones => prevZones.filter(zone => zone._id !== zoneToDelete));
        dispatch(deleteZone(zoneToDelete));
        toast({
          title: "Zone deleted",
          description: "The zone and all associated reports and dashboard panels have been successfully deleted.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting zone:', error);
        toast({
          title: "Error",
          description: "Failed to delete the zone. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        onClose();
        setZoneToDelete(null);
      }
    }
  };

  const handlePermissions = (zoneId: string) => {
    const zone = allZones.find(z => z._id === zoneId);
    if (zone) {
      setEditingZoneId(zoneId);
      setTempSelectedUsers([...zone.availableUsers]);
      setIsPermissionsModalOpen(true);
    }
  };

  const savePermissions = async () => {
    if (!editingZoneId) return;

    try {
      const response = await zoneService.updateZonePermissions(editingZoneId, tempSelectedUsers);
      if (response) {
        setAllZones(prevZones =>
          prevZones.map(zone =>
            zone._id === editingZoneId
              ? { ...zone, availableUsers: tempSelectedUsers }
              : zone
          )
        );

        toast({
          title: "Permissions updated",
          description: "Zone permissions have been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating permissions:', error);
      toast({
        title: "Error",
        description: "Failed to update permissions. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsPermissionsModalOpen(false);
      setEditingZoneId(null);
      setTempSelectedUsers([]);
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = allZones.findIndex(zone => zone._id === active.id);
      const newIndex = allZones.findIndex(zone => zone._id === over.id);

      const newZones = [...allZones];
      const [movedZone] = newZones.splice(oldIndex, 1);
      newZones.splice(newIndex, 0, movedZone);

      const updatedPositions = newZones.map((zone, index) => ({
        zoneId: zone._id,
        position: index
      }));

      try {
        const response = await zoneService.updateZonePositions(updatedPositions);
        
        if (response) {
          const updatedZones = newZones.map((zone, index) => ({
            ...zone,
            position: index
          }));
          
          // First update local state
          setAllZones(updatedZones);
          
          // Then dispatch to Redux with a slight delay to ensure proper state update
          setTimeout(() => {
            dispatch(addZones(updatedZones));
          }, 0);

          // Force refetch zones to ensure consistency
          await fetchZones();
        }
      } catch (error: any) {
        console.error('Error updating zone positions:', error);
        setAllZones(allZones);
        toast({
          title: "Error",
          description: error.response?.data?.message || "Failed to update zone positions",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setActiveId(null);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="row" gap="20px">
        <Card maxWidth="50%" height="calc(100vh - 128px)" overflowY="auto">
          <Text fontSize="2xl" fontWeight="bold" mb={3}>All Zones</Text>
          <Text fontSize="sm" mb={4}>Edit and manage your zones.</Text>
          <DndContext
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
            cancelDrop={(event) => {
              const { active, over } = event;
              return !over || active.id === over.id;
            }}
          >
            <SortableContext items={allZones.map(zone => zone._id)} strategy={verticalListSortingStrategy}>
              <SimpleGrid columns={1} spacing={4}>
                {Array.isArray(allZones) && allZones.map((zone) => (
                  <SortableZoneCard
                    key={zone._id}
                    zone={zone}
                    onRename={(name: string) => {
                      setZoneToRename({id: zone._id, name: zone.name});
                      setNewZoneName(name);
                      setIsRenameAlertOpen(true);
                    }}
                    onDelete={handleDelete}
                    onPermissions={handlePermissions}
                  />
                ))}
              </SimpleGrid>
            </SortableContext>
          </DndContext>
        </Card>
        
        <Card maxWidth="50%">
          <Text fontSize='2xl' fontWeight='700' mb='20px'>
            Create new zone
          </Text>
          <Text fontSize="sm" mb={4}>Add new zones and set permissions</Text>
          <Flex direction='column' gap='20px'>
            <Flex gap='20px'>
              <FormControl flex={1} isRequired>
                <FormLabel>Zone Name</FormLabel>
                <Input
                  placeholder='Zone Name'
                  value={zoneName}
                  onChange={(e) => setZoneName(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl flex={1} isRequired>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder='Please select'
                  value={zoneType}
                  onChange={(e) => setZoneType(e.target.value)}
                  required
                >
                  <option value='table'>Data Table</option>
                  <option value='tabs'>Data Sections</option>
                </Select>
              </FormControl>
            </Flex>
            <FormControl>
              <FormLabel>Available to users</FormLabel>
              <Flex>
                <Select 
                  placeholder="Select user" 
                  onChange={handleUserSelect}
                  flex="1"
                  mr={2}
                >
                  {Array.isArray(allUsers) && allUsers
                    .filter((user) => user && user.role !== 'admin')
                    .map((user, idx) => (
                      <option key={user?.username + idx} value={user?.username || ''}>
                        {user?.username || ''}
                      </option>
                    ))
                  }
                </Select>
                <Button colorScheme='brand' onClick={handleCreateZone}>
                  CREATE
                </Button>
              </Flex>
              <Flex flexWrap='wrap' gap='8px' mt='10px'>
                {selectedUsers.map((user) => (
                  <Tag key={user} borderRadius='full' variant='solid' colorScheme='orange'>
                    <TagLabel>{user}</TagLabel>
                    <TagCloseButton onClick={() => removeUser(user)} />
                  </Tag>
                ))}
              </Flex>
            </FormControl>
          </Flex>
        </Card>
      </Flex>

      {isOpen && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Zone
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this zone? This action cannot be undone and will also delete all associated reports and dashboard panels.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {isRenameAlertOpen && (
        <AlertDialog
          isOpen={isRenameAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setIsRenameAlertOpen(false);
            setZoneToRename(null);
            setNewZoneName('');
          }}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Rename Zone
              </AlertDialogHeader>

              <AlertDialogBody>
                <FormControl>
                  <FormLabel>New zone name</FormLabel>
                  <Input
                    value={newZoneName}
                    onChange={(e) => setNewZoneName(e.target.value)}
                    placeholder="Enter new name"
                    autoFocus
                  />
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button 
                  ref={cancelRef} 
                  onClick={() => {
                    setIsRenameAlertOpen(false);
                    setZoneToRename(null);
                    setNewZoneName('');
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  colorScheme="brand" 
                  onClick={() => {
                    if (zoneToRename && newZoneName.trim()) {
                      handleRename(zoneToRename.id, newZoneName.trim());
                    }
                  }}
                  ml={3}
                >
                  Rename
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {isPermissionsModalOpen && (
        <AlertDialog
          isOpen={isPermissionsModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setIsPermissionsModalOpen(false);
            setEditingZoneId(null);
            setTempSelectedUsers([]);
          }}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Manage Permissions
              </AlertDialogHeader>

              <AlertDialogBody>
                <FormControl>
                  <FormLabel>Available to users</FormLabel>
                  <Flex direction="column" gap={2}>
                    <Select
                      placeholder="Select user"
                      onChange={(e) => {
                        const selectedUser = e.target.value;
                        if (selectedUser && !tempSelectedUsers.includes(selectedUser)) {
                          setTempSelectedUsers(prev => [...prev, selectedUser]);
                        }
                      }}
                    >
                      {Array.isArray(allUsers) && allUsers
                        .filter((user) => user && user.role !== 'admin')
                        .map((user, idx) => (
                          <option key={user?.username + idx} value={user?.username || ''}>
                            {user?.username || ''}
                          </option>
                        ))
                      }
                    </Select>
                    <Flex flexWrap="wrap" gap={2}>
                      {tempSelectedUsers.map((user) => (
                        <Tag key={user} borderRadius="full" variant="solid" colorScheme="orange">
                          <TagLabel>{user}</TagLabel>
                          <TagCloseButton 
                            onClick={() => setTempSelectedUsers(prev => prev.filter(u => u !== user))} 
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => {
                    setIsPermissionsModalOpen(false);
                    setEditingZoneId(null);
                    setTempSelectedUsers([]);
                  }}
                >
                  Cancel
                </Button>
                <Button colorScheme="brand" onClick={savePermissions} ml={3}>
                  Save Changes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

    </Box>
  );
}