import { useEffect } from 'react';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';

const useGlobalIntercept = () => {
  const { getToken , refreshToken, logout } = useAuth();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
    async  (req) => {
      const token = getToken();
      if (token) {
        console.log("set auth token");
        req.headers['Authorization'] = `Bearer ${token}`;
      }
        return req;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          const isRememberMe = localStorage.getItem("isRememberMe") === "true";
          const refreshToken = isRememberMe ? 
            localStorage.getItem('refreshToken') : 
            sessionStorage.getItem('refreshToken');

          if (refreshToken) {
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/auth/refresh-token`, 
                { refreshToken }
              );
              
              if (response.data) {
                const { accessToken: newToken, refreshToken: newRefreshToken } = response.data;
                if (isRememberMe) {
                  localStorage.setItem('token', newToken);
                  localStorage.setItem('refreshToken', newRefreshToken);
                } else {
                  sessionStorage.setItem('token', newToken);
                  sessionStorage.setItem('refreshToken', newRefreshToken);
                }
                error.config.headers['Authorization'] = `Bearer ${newToken}`;
                return axios(error.config);
              }
            } catch (err) {
              logout();
            }
          } else {
            logout();
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};

export default useGlobalIntercept;
