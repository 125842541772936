import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  VStack,
  useToast
} from '@chakra-ui/react';
import { useAuth } from '../../../../../../contexts/AuthContext';
import Card from 'components/card/Card';
import { isAxiosError } from 'axios';
import { jwtDecode } from "jwt-decode";
import { useAxiosWithAuth } from '../../../../../../hooks/useAxiosWithAuth';

const isTokenExpired = (token: string) => {
  try {
    const decoded: any = jwtDecode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (error) {
    return true;
  }
};

const AccountSettings = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const { user, getToken } = useAuth();
  const toast = useToast();
  const axiosInstance = useAxiosWithAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getToken();
        const response = await axiosInstance.get('/users/profile', {
          headers: { Authorization: `Bearer ${token}` }
        });
        const userData = response.data;
        setFirstName(userData.firstName || '');
        setLastName(userData.lastName || '');
        setEmail(userData.email || '');
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [getToken, axiosInstance]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No token available');
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const response = await axiosInstance.put('/users/profile', 
        { firstName, lastName, email },
        config
      ); 
      toast({
        title: 'Profile updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      let errorMessage = 'An error occurred while updating the profile';
      if (isAxiosError(error)) {
        console.error('Axios error:', error.response?.data);
        console.error('Status:', error.response?.status);
        console.error('Headers:', error.response?.headers);
        errorMessage = error.response?.data?.message || errorMessage;
      }
      toast({
        title: 'Error updating profile',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  return (
    <Box>
      <Card mb='20px'>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Account Settings
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can change user account information
          </Text>
        </Flex>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
            </FormControl>
            <Button type="submit" colorScheme="blue">
              Save Changes
            </Button>
          </VStack>
        </form>
      </Card>
    </Box>
  );
};

export default AccountSettings;