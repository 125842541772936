import React, { createContext, useState, useContext, useEffect } from 'react';
import { setAuthToken } from '../services/authService';
import { User } from '../types/user';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { resetZones } from 'store/slices/zoneSlice';
import { useNavigate } from 'react-router-dom';

export interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  login: (token: string, refreshToken: string, userData: User, isRememberMe?: boolean) => void;
  logout: () => void;
  getToken: () => string;
  refreshToken: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  getToken: () => '',
  refreshToken: async () => {
  },
});

const getUserInfo = (): User | null => {
  const userInfo = JSON.parse(localStorage.getItem("user_info") || "{}");

  return Object.keys(userInfo).length > 0 ? (userInfo as User) : null;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeAuth = () => {
      const isRememberMe = localStorage.getItem("isRememberMe") === "true";
      const token = isRememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token');
      const userInfo = isRememberMe ? 
        JSON.parse(localStorage.getItem("user_info") || "null") : 
        JSON.parse(sessionStorage.getItem("user_info") || "null");

      if (token && userInfo) {
        setAuthToken(token);
        setIsAuthenticated(true);
        setUser(userInfo);
      }
    };

    initializeAuth();
  }, []);

  const login = (token: string, refreshToken: string, userData: User, isRememberMe: boolean = false) => {
    if (isRememberMe) {
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem("user_info" , JSON.stringify(userData || {}))
    } else {
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('refreshToken', refreshToken);
      sessionStorage.setItem("user_info" , JSON.stringify(userData || {}))
    }

    localStorage.setItem("isRememberMe", String(isRememberMe));
    
    // setAuthToken(token);
    setIsAuthenticated(true);
    setUser(userData);


    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
     // console.log('Decoded token:', decodedToken);
    } catch (error) {
     // console.error('Error decoding token:', error);
    }
  };

  const logout = () => {
    console.log("auth logout");
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem("user_info");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem("user_info");
    localStorage.removeItem("isRememberMe");
    setAuthToken('');
    setIsAuthenticated(false);
    setUser(null);
    dispatch(resetZones());
    // navigate('/auth/sign-in/default');
    navigate('/auth/sign-in/default', { replace: true })
  };

  const getToken = (): string => {
    const isRememberMe = localStorage.getItem("isRememberMe") === "true";
    return (isRememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token')) ?? "";
  };
  const refreshToken = async () => {
    const currentRefreshToken = localStorage.getItem('refreshToken');
    if (!currentRefreshToken) {
      throw new Error('No refresh token available');
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/refresh-token`, { refreshToken: currentRefreshToken });
      const { accessToken: newToken, refreshToken: newRefreshToken } = response.data;
      localStorage.setItem('token', newToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      return newToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, login, logout, getToken, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const logoutReload = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem("user_info");
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem("user_info");
  localStorage.removeItem("isRememberMe");
  window.location.href = "/auth/sign-in/default";
}