import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton,
  Select,
  Flex,
  Text
} from '@chakra-ui/react';

interface MultiSelectFieldProps {
  options: string[];
  value: string[];
  onChange: (values: string[]) => void;
  isDisabled?: boolean;
  placeholder?: string;
  name: string;
  isInvalid?: boolean;
  label?: string;
  isRequired?: boolean;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  options,
  value = [],
  onChange,
  isDisabled,
  placeholder = "Select options",
  name,
  isInvalid,
  label,
  isRequired
}) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (selectedValue && !value.includes(selectedValue)) {
      onChange([...value, selectedValue]);
    }
  };

  const handleRemoveValue = (valueToRemove: string) => {
    onChange(value.filter(v => v !== valueToRemove));
  };

  return (
    <FormControl isRequired={isRequired} position="relative">
      {label && <FormLabel mb={2}>{label}</FormLabel>}
      {!isDisabled && (
        <Text 
          position="absolute" 
          top="-32px" 
          right="6px" 
          fontSize="sm" 
          color="gray.500" 
          fontWeight="medium"
        >
          MultiSelect
        </Text>
      )}
      <Select
        placeholder={placeholder}
        onChange={handleSelectChange}
        isDisabled={isDisabled}
        name={name}
        value=""
        borderColor={isInvalid ? "red.500" : undefined}
      >
        {options
          .filter(option => !value.includes(option))
          .map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
      </Select>

      {value.length > 0 && (
        <Flex wrap="wrap" gap={2} mt={2}>
          {value.map((item, index) => (
            <Tag
              key={index}
              size="md"
              borderRadius="full"
              variant="solid"
              colorScheme="orange"
            >
              <TagLabel>{item}</TagLabel>
              {!isDisabled && (
                <TagCloseButton onClick={() => handleRemoveValue(item)} />
              )}
            </Tag>
          ))}
        </Flex>
      )}
    </FormControl>
  );
};

export default MultiSelectField;
