import axios from 'axios';
import { logoutReload } from 'contexts/AuthContext';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // if (error.response.status === 401 && error.response.data.expired && !originalRequest._retry) {
    if (error.response.status === 401 && error.response.data.expired) {
      // originalRequest._retry = true;
      // try {
      //   const refreshToken = localStorage.getItem('refreshToken');
      //   const response = await axios.post(process.env.FRONTEND_URL + 'api/auth/refresh-token', { refreshToken });
      //   const { token } = response.data;
      //   localStorage.setItem('token', token);
      //   api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      //   return api(originalRequest);
      // } catch (refreshError) {
      //   // If refresh fails, redirect to login
      //   localStorage.removeItem('token');
      //   localStorage.removeItem('refreshToken');
      //   window.location.href = '/login';
      //   return Promise.reject(refreshError);
      // }
      logoutReload();
    }
    return Promise.reject(error);
  }
);

export default api;