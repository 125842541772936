import React, { useCallback, useState } from 'react';
import { Box, Text, useColorModeValue, Icon, Link, Flex, VStack, Progress } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { MdOutlineCloudUpload, MdImage, MdInsertDriveFile, MdPictureAsPdf, MdDelete } from 'react-icons/md';
import { SiMicrosoftexcel, SiMicrosoftword } from 'react-icons/si';
import { IconType } from 'react-icons';

interface FileFieldProps {
  field: any;
  isEditable: boolean;
  handleTabFields: (event: { target: { name: string; value: string[] } }) => void;
  itemData: any;
  uploadUrl: string;
  onUploadSuccess?: (fileUrl: string, index?: number) => void;
  onUploadError?: (error: string) => void;
  acceptedFileTypes?: string[];
  maxFileSize?: number;
}

const FileField: React.FC<FileFieldProps> = ({
  field,
  isEditable,
  handleTabFields,
  itemData,
  uploadUrl,
  onUploadSuccess,
  onUploadError,
  acceptedFileTypes = ['image/*'],
  maxFileSize = 5 * 1024 * 1024, // 5MB
}) => {
  const [files, setFiles] = useState<string[]>(() => {
    if (field.repeater) {
      const existingFiles = itemData[field.id]?.value;
      return Array.isArray(existingFiles) ? existingFiles : [];
    } else {
      return itemData[field.id]?.value ? [itemData[field.id].value] : [];
    }
  });

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);

  const onDrop = useCallback(async (acceptedFiles: File[], index?: number) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    if (file.size > maxFileSize) {
      onUploadError?.(`File size should not exceed ${maxFileSize / (1024 * 1024)}MB`);
      setUploadStatus('error');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploadProgress(0);
      setUploadStatus('uploading');

      const response = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      const fileUrl = response.data.fileUrl;

      if (field.repeater) {
        const newFiles = [...files];
        if (index !== undefined && index < newFiles.length) {
          newFiles[index] = fileUrl;
        } else {
          newFiles.push(fileUrl);
        }
        setFiles(newFiles);
        handleTabFields({
          target: {
            name: field.id,
            value: newFiles,
          }
        });
      } else {
        handleTabFields({
          target: {
            name: field.id,
            value: [fileUrl],
          },
        });
      }
      onUploadSuccess?.(fileUrl, index);
      setUploadStatus('success');
    } catch (error) {
      console.error('Error uploading file:', error);
      onUploadError?.('Error uploading file. Please try again.');
      setUploadStatus('error');
    }
  }, [field.id, handleTabFields, uploadUrl, maxFileSize, onUploadSuccess, onUploadError, files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles),
    accept: acceptedFileTypes.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
    maxSize: maxFileSize,
    disabled: !isEditable,
  });

  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const bgColor = useColorModeValue('gray.100', 'gray.700');

  const getFileIcon = (fileUrl: any): IconType => {
    if (typeof fileUrl !== 'string' || fileUrl.length === 0) {
      return MdInsertDriveFile;
    }
    const parts = fileUrl.split('.');
    if (parts.length < 2) {
      return MdInsertDriveFile;
    }
    const extension = parts[parts.length - 1].toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return MdImage;
      case 'pdf':
        return MdPictureAsPdf;
      case 'doc':
      case 'docx':
        return SiMicrosoftword;
      case 'xls':
      case 'xlsx':
        return SiMicrosoftexcel;
      default:
        return MdInsertDriveFile;
    }
  };

  const renderFileUpload = (index?: number) => {
    const fileUrl = index !== undefined ? files[index] : files[0];

    if (fileUrl && typeof fileUrl === 'string') {
      const FileIcon = getFileIcon(fileUrl);
      const fullFileName = fileUrl.split('/').pop() || 'Uploaded File';
      const displayFileName = fullFileName.split('-').slice(1).join('-');
      const token = localStorage.getItem('token') || '';
      const companyId = fileUrl.split('/')[2];
      const downloadUrl = `${process.env.REACT_APP_API_URL}/api/company/download-file/${companyId}/${fullFileName}`;
      
      const handleDownload = async (e: React.MouseEvent) => {
        e.preventDefault();
        try {
          const token = localStorage.getItem('token') || sessionStorage.getItem('token');
          
          if (!token) {
            if (onUploadError) {
              onUploadError('Authentication error: Please log in again');
            }
            return;
          }

          const response = await fetch(downloadUrl, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': '*/*',
            },
            credentials: 'include'
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error('Download error:', errorData);
            throw new Error(`Download failed: ${response.status} ${response.statusText}`);
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = displayFileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } catch (error) {
          console.error('Download error:', error);
          if (onUploadError) {
            onUploadError('Failed to download the file. Please try again.');
          }
        }
      };

      return (
        <Flex alignItems="center" mt={2} p={4} borderWidth={1} borderRadius="md">
          <Icon as={FileIcon} w={5} h={5} mr={2} color="orange.500"/>
          <Link 
            onClick={handleDownload}
            color="blue.500"
            cursor="pointer"
          >
            {displayFileName}
          </Link>
          {isEditable && (
            <Icon
              as={MdDelete}
              w={5}
              h={5}
              ml="auto"
              color="red.500"
              cursor="pointer"
              onClick={() => {
                if (field.repeater) {
                  const newFiles = files.filter((_, i) => i !== index);
                  setFiles(newFiles);
                  handleTabFields({
                    target: {
                      name: field.id,
                      value: newFiles,
                    },
                  });
                } else {
                  setFiles([]);
                  handleTabFields({
                    target: {
                      name: field.id,
                      value: [],
                    },
                  });
                }
              }}
            />
          )}
        </Flex>
      );
    }

    return (
      <Box>
        <Box
          {...getRootProps()}
          borderWidth={2}
          borderStyle="dashed"
          borderColor={borderColor}
          borderRadius="md"
          p={4}
          textAlign="center"
          bg={bgColor}
          cursor={isEditable ? 'pointer' : 'default'}
        >
          <input {...getInputProps()} />
          <Icon as={MdOutlineCloudUpload} w={8} h={8} color="gray.500" />
          <Text mt={2}>
            {isDragActive ? 'Drop the file here' : 'Click or drag file to upload'}
          </Text>
        </Box>
        {uploadStatus && (
          <Box mt={4}>
            {uploadStatus === 'uploading' && (
              <Box>
                <Text mb={2}>Uploading...</Text>
                <Progress value={uploadProgress} size="sm" colorScheme="brand" />
              </Box>
            )}
            {uploadStatus === 'success' && (
              <Text color="green.500">File uploaded successfully!</Text>
            )}
            {uploadStatus === 'error' && (
              <Text color="red.500">Error uploading file. Please try again.</Text>
            )}
          </Box>
        )}
      </Box>
    );
  };

  if (field.repeater) {
    return (
      <VStack align="stretch" spacing={4}>
        {files.map((_, index) => (
          <Box key={index}>
            {renderFileUpload(index)}
          </Box>
        ))}
        {isEditable && files.length < (field.maxRepeaterFields || Infinity) && (
          <Box>
            {renderFileUpload(files.length)}
          </Box>
        )}
      </VStack>
    );
  }

  return renderFileUpload();
};

export default FileField;