import React, { memo } from 'react';
import {
    Box,
    Flex,
    Text,
    Icon
} from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';

const DraggedColumnHeader = memo(({ id, borderColor }: { id: string, borderColor: string }) => {
    return (
        <Box
            padding="12px"
            borderColor="orange.500"
            borderWidth="2px"
            borderStyle="solid"
            backgroundColor="white"
            width="200px"
            opacity={0.9}
            borderRadius="4px"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
        >
            <Flex align="center" height="100%">
                <Icon as={DragHandleIcon} boxSize={3} color="orange.500" mr={2} />
                <Text fontWeight="500">{id}</Text>
            </Flex>
        </Box>
    );
});

export default DraggedColumnHeader;