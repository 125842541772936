import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const isRememberMe = localStorage.getItem("isRememberMe") === "true";
      const token = isRememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token');
      const userInfo = isRememberMe ? 
        localStorage.getItem("user_info") : 
        sessionStorage.getItem("user_info");
      
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Or your loading component
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in/default" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;