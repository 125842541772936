// client/src/views/admin/main/zones/ImportWizardTabs.tsx
import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  VStack,
  Text,
  Box,
  Image,
  Icon,
  Checkbox,
  Select,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { FaFileUpload, FaCheckCircle } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import CustomPopup from 'components/CustomPopup';
import { useDropzone } from 'react-dropzone';
import wizardImage from 'assets/img/wizard.png';
import { zoneService } from 'services/zoneService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CloseButton } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useAuth } from 'contexts/AuthContext';

interface ImportWizardTabsProps {
  isOpen: boolean;
  onClose: () => void;
  zoneId: string;
  onImport: (data: any[]) => void;
}

const DropFileZone: React.FC<{ onFileUpload: (file: File) => void }> = ({ onFileUpload }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onFileUpload(acceptedFiles[0]);
      }
    }, [onFileUpload]),
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
      'text/csv': ['.csv']
    },
    multiple: false
  });

  return (
    <Box
      {...getRootProps()}
      borderWidth={2}
      borderStyle="dashed"
      borderRadius="md"
      p={8}
      textAlign="center"
    >
      <input {...getInputProps()} />
      <VStack spacing={4}>
        <Icon as={FaFileUpload} boxSize={8} color="brand.500" />
        <Text fontWeight="bold">Drop your Excel file here</Text>
        <Text color="brand.500">or upload from your computer</Text>
        <Text fontSize="sm" color="gray.500">Only CSV, XLS, or XLSX are allowed</Text>
      </VStack>
    </Box>
  );
};

interface Mapping {
  excelColumn: string;
  fieldId: string;
}

interface TabData {
  [key: string]: any;
}

interface ItemData {
  tabs: {
    [key: string]: TabData;
  };
}

interface ZoneItem {
  _id: string;
  data: {
    [key: string]: {
      value?: any;
      [key: string]: any;
    };
  };
}

const formatCellValue = (cellValue: any, fieldType: string): any => {
  switch (fieldType) {
    case 'number':
      return typeof cellValue === 'number' ? 
        cellValue : 
        Number(String(cellValue).replace(/[^0-9.-]/g, ''));
    case 'date':
      if (cellValue instanceof Date) {
        return format(cellValue, 'yyyy-MM-dd');
      }
      const dateValue = new Date(cellValue);
      return !isNaN(dateValue.getTime()) ? 
        format(dateValue, 'yyyy-MM-dd') : 
        cellValue;
    default:
      return String(cellValue).trim();
  }
};

const ImportWizardTabs: React.FC<ImportWizardTabsProps> = ({ isOpen, onClose, zoneId, onImport }) => {
  const [step, setStep] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [excelColumns, setExcelColumns] = useState<string[]>([]);
  const [zoneTabs, setZoneTabs] = useState<any[]>([]);
  const [zoneFields, setZoneFields] = useState<any[]>([]);
  const [columnMapping, setColumnMapping] = useState<{ [key: string]: { fieldId: string; tabId: string } }>({});
  const [excludeFirstRow, setExcludeFirstRow] = useState(true);
  const toast = useToast();
  const [activeTab, setActiveTab] = useState<string>('');
  const [tabMappings, setTabMappings] = useState<{ [tabId: string]: Mapping[] }>({});
  const { user } = useAuth();

  useEffect(() => {
    if (isOpen && zoneId) {
      fetchZoneStructure();
    }
  }, [isOpen, zoneId]);

  const fetchZoneStructure = async () => {
    try {
      const zoneData = await zoneService.getZoneById(zoneId);
      setZoneTabs(zoneData.data.tabs);
      setZoneFields(zoneData.data.fields);
    } catch (error) {
      console.error('Error fetching zone structure:', error);
    }
  };

  const handleFileUpload = (uploadedFile: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as string[][];
      
      if (parsedData.length > 0) {
        const headers = parsedData[0];
        setExcelColumns(headers);
        setColumnMapping({});
      }
    };
    reader.readAsArrayBuffer(uploadedFile);
    setFile(uploadedFile);
  };

  const handleColumnMapping = (excelColumn: string, fieldId: string, tabId: string) => {
    setColumnMapping(prev => ({
      ...prev,
      [excelColumn]: { fieldId, tabId }
    }));
  };

  const handleMapping = (tabId: string, excelColumn: string, fieldId: string) => {
    setTabMappings(prev => ({
      ...prev,
      [tabId]: [
        ...(prev[tabId] || []).filter(m => m.excelColumn !== excelColumn),
        { excelColumn, fieldId }
      ]
    }));
  };

  const handleImport = async () => {
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = e.target?.result;
          const workbook = XLSX.read(data, { type: 'array', cellDates: true });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(worksheet, { 
            header: 1,
            raw: false,
            dateNF: 'yyyy-MM-dd'
          }) as any[][];

          const startIndex = excludeFirstRow ? 1 : 0;
          const importedData = parsedData.slice(startIndex)
            .filter(row => row.some(cell => cell !== null && cell !== '' && cell !== undefined))
            .map((row) => {
              // Create the item data structure
              const itemData: ItemData = {
                tabs: {}
              };

              // Map the fields to their respective tabs
              Object.entries(tabMappings).forEach(([tabId, mappings]) => {
                itemData.tabs[tabId] = {};
                mappings.forEach(mapping => {
                  const columnIndex = excelColumns.indexOf(mapping.excelColumn);
                  if (columnIndex !== -1) {
                    const cellValue = row[columnIndex];
                    if (cellValue !== null && cellValue !== '' && cellValue !== undefined) {
                      const field = zoneFields.find(f => f.id === mapping.fieldId);
                      if (field) {
                        itemData.tabs[tabId][field.id] = formatCellValue(cellValue, field.type);
                      }
                    }
                  }
                });
              });
              
              return itemData;
            });

          console.log('Importing data:', importedData); // Debug log

          const createdItems: ZoneItem[] = [];
          for (const itemData of importedData) {
            try {
              const response = await zoneService.createZoneItem(zoneId, itemData, user.companyId);
              createdItems.push(response);
            } catch (itemError) {
              console.error('Error importing item:', itemError);
            }
          }

          if (createdItems.length > 0) {
            // Get existing items BEFORE the import
            const existingItems = await zoneService.getZoneItems(zoneId);
            const existingIds = new Set(existingItems.map((item: ZoneItem) => item._id));

            // Flatten the data structure for the table
            const flattenedItems = createdItems.map((item: ZoneItem) => {
              const flatItem: any = { _id: item._id };
              Object.entries(item.data).forEach(([key, content]: [string, any]) => {
                if (typeof content === 'object') {
                  if ('value' in content) {
                    flatItem[key] = content.value;
                  } else if (typeof content === 'object') {
                    Object.entries(content).forEach(([fieldId, value]) => {
                      flatItem[fieldId] = value;
                    });
                  }
                }
              });
              return flatItem;
            });

            // Flatten existing items (only those not in createdItems)
            const flattenedExistingItems = existingItems
              .filter((item: ZoneItem) => !createdItems.some(newItem => newItem._id === item._id))
              .map((item: ZoneItem) => {
                const flatItem: any = { _id: item._id };
                Object.entries(item.data).forEach(([key, content]: [string, any]) => {
                  if (typeof content === 'object') {
                    if ('value' in content) {
                      flatItem[key] = content.value;
                    } else if (typeof content === 'object') {
                      Object.entries(content).forEach(([fieldId, value]) => {
                        flatItem[fieldId] = value;
                      });
                    }
                  }
                });
                return flatItem;
              });

            // Combine existing and new items
            onImport([...flattenedExistingItems, ...flattenedItems]);
            setStep(2);
            toast({
              title: "Import Successful",
              description: `${createdItems.length} out of ${importedData.length} items have been imported`,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          } else {
            throw new Error("No items were successfully imported");
          }
        } catch (error) {
          throw error;
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Error in handleImport:', error);
      toast({
        title: "Import Failed",
        description: error instanceof Error ? error.message : "An error occurred while importing items",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const resetWizard = () => {
    setStep(0);
    setFile(null);
    setExcelColumns([]);
    setColumnMapping({});
    setTabMappings({});
    setExcludeFirstRow(true);
  };

  const handleClose = () => {
    resetWizard();
    onClose();
  };

  const steps = [
    { title: 'Select a file', description: 'Upload your Excel file' },
    { title: 'Headers Mapping', description: 'Please map the item fields to the Excel columns' },
    { title: 'Import', description: 'Review the results' }
  ];

  return (
    <CustomPopup isOpen={isOpen} onClose={handleClose} hideCloseButton>
      <VStack width="600px" maxWidth="600px" spacing={0}>
        <Box width="100%" bg="gray.200" position="relative">
          <Flex justifyContent="center" alignItems="center" height="50px">
            <Image src={wizardImage} alt="Wizard" height="33px" objectFit="contain" />
          </Flex>
          <CloseButton
            position="absolute"
            top={2}
            right={2}
            onClick={handleClose}
          />
        </Box>
        
        <Box width="100%" padding="10">
          <Text fontSize="2xl" align="center" fontWeight="normal" mb="8">
            Import data from Excel
          </Text>

          <Box display="flex" justifyContent="center" width="100%">
            <Flex align="center" mb="8" width="300px">
              {steps.map((s, i) => (
                <React.Fragment key={i}>
                  <Flex direction="column" align="center">
                    <Box
                      w="15px"
                      h="15px"
                      borderRadius="full"
                      bg={i <= step ? 'orange.500' : 'gray.200'}
                    />
                  </Flex>
                  {i < 2 && (
                    <Box flex="1" h="2px" bg={i < step ? 'orange.500' : 'gray.200'} mx="2" />
                  )}
                </React.Fragment>
              ))}
            </Flex>
          </Box>

          <Text fontSize="lg" align="center" color="orange.500" mb={0}>
            {steps[step].title}
          </Text>
          <Text fontSize="sm" align="center" mb={10} color="gray.600">
            {steps[step].description}
          </Text>

          {step === 0 && (
            <VStack spacing={8} align="center">
              {!file ? (
                <DropFileZone onFileUpload={handleFileUpload} />
              ) : (
                <VStack spacing={4} align="center">
                  <Icon as={FaCheckCircle} color="green.500" boxSize={12} />
                  <Text fontWeight="bold">File uploaded successfully</Text>
                  <Text color="gray.600">{file.name}</Text>
                </VStack>
              )}
            </VStack>
          )}

          {step === 1 && (
            <VStack spacing={4} align="stretch">
              <FormControl>
                <Checkbox
                  isChecked={excludeFirstRow}
                  onChange={(e) => setExcludeFirstRow(e.target.checked)}
                >
                  Exclude first row of spreadsheet from import
                </Checkbox>
              </FormControl>

              <Tabs>
                <TabList>
                  {zoneTabs.map((tab) => (
                    <Tab 
                      key={tab.id}
                      _selected={{ color: 'orange.500', borderColor: 'orange.500' }}
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {zoneTabs.map((tab) => (
                    <TabPanel key={tab.id}>
                      <VStack align="stretch" spacing={3}>
                        {zoneFields
                          .filter(field => field.tabId === tab.id)
                          .map(field => (
                            <FormControl key={field.id}>
                              <Flex alignItems="center">
                                <FormLabel flex="1" mb={0}>{field.label}</FormLabel>
                                <Select
                                  width="200px"
                                  value={tabMappings[tab.id]?.find(m => m.fieldId === field.id)?.excelColumn || ''}
                                  onChange={(e) => handleMapping(tab.id, e.target.value, field.id)}
                                  placeholder="Select Excel column"
                                >
                                  {excelColumns.map(column => (
                                    <option key={column} value={column}>
                                      {column}
                                    </option>
                                  ))}
                                </Select>
                              </Flex>
                            </FormControl>
                          ))
                        }
                      </VStack>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </VStack>
          )}

          {step === 2 && (
            <VStack spacing={4} align="center">
              <Icon as={FaCheckCircle} color="green.500" boxSize={16} />
              <Text fontSize="xl" fontWeight="bold">
                Import Successful
              </Text>
            </VStack>
          )}

          <HStack mt="4" justifyContent="space-between">
            {step > 0 && (
              <Button
                onClick={() => setStep(step - 1)}
                variant="outline"
                fontWeight="normal"
                colorScheme="gray"
              >
                Previous
              </Button>
            )}
            {step < 2 && (
              <Button
                colorScheme="orange"
                fontWeight="normal"
                onClick={() => {
                  if (step === 1) {
                    handleImport();
                  } else {
                    setStep(step + 1);
                  }
                }}
                isDisabled={step === 0 && !file}
              >
                {step === 1 ? 'Import' : 'Next'}
              </Button>
            )}
            {step === 2 && (
              <Button colorScheme="orange" onClick={handleClose}>
                Close
              </Button>
            )}
          </HStack>
        </Box>
      </VStack>
    </CustomPopup>
  );
};

export default ImportWizardTabs;