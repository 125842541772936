import React, { useMemo } from 'react';
import { Box, Flex, Text, Menu, MenuButton, MenuItem, MenuList, Button, Tooltip } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface LabelOption {
  label: string;
  color: string;
}

interface LabelFieldProps {
  options: LabelOption[];
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  updateFieldOptions: (newOptions: LabelOption[]) => void;
  variant?: 'table' | 'default';
}

const LabelField: React.FC<LabelFieldProps> = React.memo(({
  options,
  value = "",
  onChange,
  isDisabled,
  updateFieldOptions,
  variant = 'default'
}) => {
  const selectedOption = useMemo(() => {
    const found = options?.find(option => option.label === value);
    if (!found && options?.length > 0) {
      onChange(options[0].label);
      return options[0];
    }
    return found || { label: "Please select", color: "" };
  }, [value, options, onChange]);

  const buttonStyles = variant === 'table' ? {
    border: "0",
    p: "0 10px",
    height: "auto",
    _hover: { bg: 'white' },
    _active: { bg: 'white' }
  } : {
    borderColor: "#ccc",
    borderWidth: "1px",
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        isDisabled={isDisabled}
        w="100%"
        bg="white"
        fontWeight="normal"
        color="gray.700"
        {...buttonStyles}
      >
        <Flex align="center" minWidth={0}>
          {selectedOption.color && (
            <Box 
              flexShrink={0}
              w="20px" 
              h="20px" 
              borderRadius="50%" 
              bg={selectedOption.color} 
              mr="10px"
            />
          )}
          <Tooltip
            bg="gray.800"
            label={selectedOption.label}
            isDisabled={selectedOption.label.length <= 20}
            placement="top"
            hasArrow
          >
            <Text 
              fontSize="14px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {selectedOption.label}
            </Text>
          </Tooltip>
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => onChange("Please select")}>
          <Text color="gray.700">Please select</Text>
        </MenuItem>
        {options?.map((option, index) => (
          <MenuItem key={index} onClick={() => onChange(option.label)}>
            <Flex align="center">
              <Box 
                flexShrink={0}
                w="20px" 
                h="20px" 
                borderRadius="50%" 
                bg={option.color} 
                mr="10px" 
              />
              <Text color="gray.700">{option.label}</Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
});

export default LabelField;
