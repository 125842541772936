// Chakra Imports
import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Image,
  Box,
} from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
// Assets
import { routes } from 'routes';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, NavLink } from 'react-router-dom';
import { useState } from 'react';
import { FlexibleRoutesType } from '../../types/types';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export default function HeaderLinks(props: {
  secondary: boolean;
  companyInfo: {
    logo?: string;
    name: string;
  };
  [x: string]: any;
}) {
  const { secondary, theme, setTheme, companyInfo } = props;
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [currentZoneName,setCurrentZoneName] = useState<string>("")
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

  const handleLogout = async () => {
    try {
      logout();
      // navigate('/auth/sign-in/default');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
    >
       <Flex w="70%" mb="0px">
            <Text
              pl="15px"
              w="100%"
              minWidth="150px"
              mr="20px"
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              Hey, {user ? user.firstName : 'Guest'}
            </Text>
          </Flex>

      <SidebarResponsive routes={(routes({ currentZoneName, setCurrentZoneName }) as FlexibleRoutesType[])} />
     {/*  <Menu>
        <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Mark all read
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon UI Dashboard PRO" />
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon Design System Free" />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}

     
   {/*    <Configurator
        mini={props.mini}
        setMini={props.setMini}
        theme={theme}
        setTheme={setTheme}
      /> */}

      <Flex alignItems="center">
        {companyInfo.logo && (
          <Image
            src={companyInfo.logo.startsWith('http') 
              ? companyInfo.logo 
              : `${API_BASE_URL}${companyInfo.logo}`}
            alt={`${companyInfo.name} logo`}
            boxSize="40px"
            objectFit="contain"
            borderRadius="md"
            mr={2}
            crossOrigin="anonymous"
            fallback={
              <Box 
                boxSize="40px"
                bg="gray.100" 
                display="flex" 
                alignItems="center" 
                justifyContent="center"
                borderRadius="md"
                mr={2}
              >
                <Text color="gray.500" fontSize="xs">Logo</Text>
              </Box>
            }
          />
        )}
        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              name={user ? user.firstName : 'Guest'}
              bg="#FE7053"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            zIndex="999"
          >
            <Flex flexDirection="column" p="10px">
              <MenuItem
                as={NavLink} to="/admin/main/account/settings"
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Profile Settings</Text>
              </MenuItem>
              {user && user.role === 'admin' && (
                <MenuItem
                  as={NavLink}
                  to="/admin/main/account/company"
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  borderRadius="8px"
                  px="14px"
                >
                  <Text fontSize="sm">Company Settings</Text>
                </MenuItem>
              )}
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={handleLogout}
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
