import React, { useState, useCallback } from 'react';
import {
  Button,
  VStack,
  Text,
  Box,
  Image,
  useSteps,
  Icon,
  Checkbox,
  Select,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  useColorModeValue,
  CloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FaFileUpload, FaCheckCircle, FaFont, FaHashtag, FaList } from 'react-icons/fa';
import { CalendarIcon, ChevronDownIcon } from '@chakra-ui/icons';
import * as XLSX from 'xlsx';
import CustomPopup from 'components/CustomPopup';
import { useDropzone } from 'react-dropzone';
import wizardImage from 'assets/img/wizard.png';
import { parse, isValid, format } from 'date-fns';

interface ImportWizardProps {
  isOpen: boolean;
  onClose: () => void;
  columns: string[];
  onImport: (data: any[], newColumns: { name: string; type: string }[], updatedColumns: string[]) => void;
}

const ImportWizard: React.FC<ImportWizardProps> = ({ isOpen, onClose, columns, onImport }) => {
  const [step, setStep] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [excelColumns, setExcelColumns] = useState<string[]>([]);
  const [columnMapping, setColumnMapping] = useState<{ [key: string]: string }>({});
  const [importedRowCount, setImportedRowCount] = useState(0);
  const [excludeFirstRow, setExcludeFirstRow] = useState(true);

  const steps = [
    { title: 'Select a file', description: 'Upload your Excel file' },
    { title: 'Headers Mapping', description: 'Please map the Excel columns to the table columns' },
    { title: 'Import', description: 'Review the results' },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: step,
    count: steps.length,
  });

  const handleFileUpload = (uploadedFile: File) => {
    const fileExtension = uploadedFile.name.split('.').pop()?.toLowerCase();
    if (['csv', 'xls', 'xlsx'].includes(fileExtension || '')) {
      setFile(uploadedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        
        if (parsedData.length > 0) {
          const headers = parsedData[0] as string[];
          setExcelColumns(headers);
          setColumnMapping(
            headers.reduce((acc, header) => ({ ...acc, [header]: '' }), {})
          );
        }
      };
      reader.readAsArrayBuffer(uploadedFile);
    } else {
      alert('Please upload a CSV, XLS, or XLSX file.');
    }
  };

  const handleColumnMapping = (excelColumn: string, zoneColumn: string) => {
    setColumnMapping((prev) => ({ ...prev, [excelColumn]: zoneColumn }));
  };

  const formatNumber = (value: any) => {
    if (value == null) return null;
    if (typeof value === 'string') {
      // Remove all non-numeric characters except for the decimal point
      const cleanedValue = value.replace(/[^0-9.]/g, '');
      return Number(cleanedValue);
    }
    return typeof value === 'number' ? value : null;
  };

  const isValidDateString = (value: string): boolean => {
    // Add regex or other validation logic here
    return /^\d{1,2}[-/.]\d{1,2}[-/.]\d{2,4}$/.test(value);
  };

  const formatDate = (value: any): string | null => {
    console.log('formatDate input:', value, 'type:', typeof value);
    
    if (value == null) {
      console.log('formatDate: null value');
      return null;
    }
    
    let date: Date | null = null;

    // Handle Excel serial number dates
    if (typeof value === 'number') {
      console.log('formatDate: handling number value:', value);
      if (value > 0 && value < 50000) {
        try {
          const excelEpoch = new Date(1899, 11, 30);
          date = new Date(excelEpoch.getTime() + (value * 24 * 60 * 60 * 1000));
          console.log('formatDate: converted Excel date:', date);
          
          if (value > 60) {
            date.setTime(date.getTime() - (24 * 60 * 60 * 1000));
            console.log('formatDate: adjusted for leap year bug:', date);
          }
        } catch (error) {
          console.warn('formatDate: Excel date conversion failed:', error);
          return null;
        }
      }
    } 
    // Handle string dates
    else if (typeof value === 'string') {
      console.log('formatDate: handling string value:', value);
      
      if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        console.log('formatDate: already in ISO format');
        return value;
      }
      
      const formats = [
        'dd/MM/yyyy',
        'MM/dd/yyyy',
        'yyyy-MM-dd',
        'dd-MM-yyyy',
        'MM-dd-yyyy',
        'dd.MM.yyyy'
      ];
      
      for (const formatString of formats) {
        try {
          const parsedDate = parse(value, formatString, new Date());
          console.log(`formatDate: trying format ${formatString}:`, parsedDate);
          if (isValid(parsedDate)) {
            date = parsedDate;
            console.log('formatDate: found valid format:', formatString);
            break;
          }
        } catch (error) {
          continue;
        }
      }
    }
    // Handle Date objects
    else if (value instanceof Date) {
      date = value;
    }

    if (date && isValid(date)) {
      const year = date.getFullYear();
      console.log('formatDate: final date:', date, 'year:', year);
      if (year >= 1900 && year <= 2100) {
        try {
          const result = format(date, 'yyyy-MM-dd');
          console.log('formatDate: final result:', result);
          return result;
        } catch (error) {
          console.warn('formatDate: formatting failed:', error);
          return null;
        }
      }
    }

    console.warn('formatDate: failed to parse date:', value);
    return null;
  };

  const handleImport = () => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'array', cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { 
        header: 1,
        raw: false,
        dateNF: 'yyyy-MM-dd'
      }) as any[][];

      const startIndex = excludeFirstRow ? 1 : 0;
      const importedData = parsedData.slice(startIndex)
        .filter(row => row.some(cell => cell !== null && cell !== '' && cell !== undefined))
        .map((row) => {
          const newRow: { [key: string]: any } = {};
          excelColumns.forEach((excelColumn, index) => {
            const zoneColumn = columnMapping[excelColumn];
            if (zoneColumn) {
              const cellValue = row[index];
              if (cellValue !== null && cellValue !== '' && cellValue !== undefined) {
                const columnName = zoneColumn.replace(/^new_(text|number|date|select)_/, '');
                
                if (zoneColumn.startsWith('new_number_')) {
                  newRow[columnName] = formatNumber(cellValue);
                } else if (zoneColumn.startsWith('new_date_')) {
                  try {
                    // Handle Excel date number format
                    if (typeof cellValue === 'number') {
                      const excelEpoch = new Date(1899, 11, 30);
                      const date = new Date(excelEpoch.getTime() + (cellValue * 24 * 60 * 60 * 1000));
                      if (cellValue > 60) {
                        date.setTime(date.getTime() - (24 * 60 * 60 * 1000));
                      }
                      newRow[columnName] = format(date, 'yyyy-MM-dd');
                    } 
                    // Handle string dates
                    else if (typeof cellValue === 'string') {
                      if (/^\d{4}-\d{2}-\d{2}$/.test(cellValue)) {
                        newRow[columnName] = cellValue;
                      } else {
                        const formats = ['dd/MM/yyyy', 'MM/dd/yyyy', 'yyyy-MM-dd', 'dd-MM-yyyy', 'MM-dd-yyyy', 'dd.MM.yyyy'];
                        let validDate = null;
                        
                        for (const formatString of formats) {
                          const parsedDate = parse(cellValue, formatString, new Date());
                          if (isValid(parsedDate)) {
                            validDate = parsedDate;
                            break;
                          }
                        }
                        
                        if (validDate) {
                          newRow[columnName] = format(validDate, 'yyyy-MM-dd');
                        } else {
                          newRow[columnName] = null;
                        }
                      }
                    }
                    // Handle Date objects
                    else if (cellValue instanceof Date && isValid(cellValue)) {
                      newRow[columnName] = format(cellValue, 'yyyy-MM-dd');
                    } else {
                      newRow[columnName] = null;
                    }
                  } catch (error) {
                    console.warn(`Failed to parse date value: ${cellValue}`, error);
                    newRow[columnName] = null;
                  }
                } else if (zoneColumn.startsWith('new_select_')) {
                  newRow[columnName] = String(cellValue).trim();
                } else {
                  newRow[columnName] = String(cellValue).trim();
                }
              }
            }
          });
          return newRow;
        })
        .filter(row => Object.keys(row).length > 0);

      const newColumns = Object.entries(columnMapping)
        .filter(([_, value]) => value.startsWith('new_'))
        .map(([_, value]) => ({
          name: value.replace(/^new_(text|number|date|select)_/, ''),
          type: value.split('_')[1]
        }));

      const updatedColumns = [...columns];
      newColumns.forEach(({ name }) => {
        if (!updatedColumns.includes(name)) {
          updatedColumns.push(name);
        }
      });

      setImportedRowCount(importedData.length);
      onImport(importedData, newColumns, updatedColumns);
      setStep(2);
      setActiveStep(2);
    };
    reader.readAsArrayBuffer(file);
  };

  const DropFileZone: React.FC<{ onFileUpload: (file: File) => void }> = ({ onFileUpload }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
          onFileUpload(acceptedFiles[0]);
        }
      }, [onFileUpload]),
      accept: {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-excel': ['.xls'],
        'text/csv': ['.csv']
      },
      multiple: false
    });

    return (
      <Box
        {...getRootProps()}
        borderWidth={2}
        borderStyle="dashed"
        borderRadius="md"
        p={8}
        textAlign="center"
        bg={isDragActive ? 'gray.100' : 'white'}
      >
        <input {...getInputProps()} />
        <VStack spacing={4}>
          <FaFileUpload size={32} color="Green.400" />
          <Text fontWeight="bold">Drop your Excel file here</Text>
          <Text color="brand.500">or upload from your computer</Text>
          <Text fontSize="sm" color="gray.500">Only CSV, XLS, or XLSX are allowed</Text>
        </VStack>
      </Box>
    );
  };

  const textColor = useColorModeValue('gray.700', 'white');
  const activeColor = useColorModeValue('orange.500', 'orange.200');
  const inactiveColor = useColorModeValue('gray.200', 'gray.600');

  const resetWizard = () => {
    setStep(0);
    setActiveStep(0);
    setFile(null);
    setExcelColumns([]);
    setColumnMapping({});
    setImportedRowCount(0);
    setExcludeFirstRow(true);
  };

  return (
    <CustomPopup isOpen={isOpen} onClose={onClose} hideCloseButton>
      <VStack width="600px" maxWidth="600px" spacing={0}>
        <Box width="100%" bg="gray.200" position="relative">
          <Flex justifyContent="center" alignItems="center" height="50px">
            <Image src={wizardImage} alt="Wizard" height="33px" objectFit="contain" />
          </Flex>
          <CloseButton
            position="absolute"
            top={2}
            right={2}
            onClick={() => {
              resetWizard();
              onClose();
            }}
          />
        </Box>
        <Box width="100%" padding="10">
          <Text fontSize="2xl" align="center" fontWeight="normal" mb="4">Import data from Excel</Text>
         <Box display="flex" justifyContent="center" width="100%">
          <Flex align="center" mb="8" width="300px">
            {steps.map((s, i) => (
              <React.Fragment key={i}>
                <Flex direction="column" align="center">
                  <Box
                    w="15px"
                    h="15px"
                    borderRadius="full"
                    bg={i <= step ? activeColor : inactiveColor}
                  />
                  
                </Flex>
                {i < steps.length - 1 && (
                  <Box
                    flex="1"
                    h="2px"
                    bg={i < step ? activeColor : inactiveColor}
                    mx="2"
                  />
                )}
              </React.Fragment>
            ))}
          </Flex>
          </Box>
          <Text fontSize="lg" align="center" color="orange.500" mb={0}>{steps[step].title}</Text>
          <Text fontSize="ms" align="center" mb={10}>{steps[step].description}</Text>

          {step === 0 && (
            <VStack spacing={8} align="center">
              {!file ? (
                <DropFileZone onFileUpload={handleFileUpload} />
              ) : (
                <VStack spacing={4} align="center">
                  <Icon as={FaCheckCircle} color="green.500" boxSize={12} />
                  <Text fontWeight="bold">
                    File uploaded successfully
                  </Text>
                  <Text color="gray.600">
                    {file.name}
                  </Text>
                  <Button
                    leftIcon={<FaFileUpload />}
                    onClick={() => setFile(null)}
                    size="sm"
                    variant="outline"
                    colorScheme="gray"
                  >
                    Upload Different File
                  </Button>
                </VStack>
              )}
            </VStack>
          )}

          {step === 1 && (
            <VStack spacing={4} align="stretch">
             <FormControl>
              <Checkbox
                isChecked={excludeFirstRow}
                onChange={(e) => setExcludeFirstRow(e.target.checked)}
              >
                Exclude first row of spreadsheet from import
              </Checkbox>
            </FormControl>
            
              {excelColumns.map((excelColumn) => (
               <FormControl key={excelColumn}>
                <Flex alignItems="center">
                 <FormLabel flex="1" mb={0}>{excelColumn}</FormLabel>
                 <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} width="300px" textAlign="left">
                    {columnMapping[excelColumn] ? 
                      columnMapping[excelColumn].startsWith('new_') ? 
                        columnMapping[excelColumn].split('_').slice(2).join(' ').replace(/\b\w/g, c => c.toUpperCase()) 
                        : columnMapping[excelColumn]
                      : "Select Zone column"
                    }
                  </MenuButton>
                  <MenuList>
                    {columns.map((column) => (
                      <MenuItem key={column} value={column} onClick={() => handleColumnMapping(excelColumn, column)}>
                        {column}
                      </MenuItem>
                    ))}
                    <MenuItem value={`new_text_${excelColumn}`} onClick={() => handleColumnMapping(excelColumn, `new_text_${excelColumn}`)}>
                      <HStack>
                        <Icon as={FaFont} color="orange.500" />
                        <Text>New Text Column</Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem value={`new_number_${excelColumn}`} onClick={() => handleColumnMapping(excelColumn, `new_number_${excelColumn}`)}>
                      <HStack>
                        <Icon as={FaHashtag} color="brand.500" />
                        <Text>New Number Column</Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem value={`new_select_${excelColumn}`} onClick={() => handleColumnMapping(excelColumn, `new_select_${excelColumn}`)}>
                      <HStack>
                        <Icon as={FaList} color="horizonYellow.500" />
                        <Text>New Select Column</Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem value={`new_date_${excelColumn}`} onClick={() => handleColumnMapping(excelColumn, `new_date_${excelColumn}`)}>
                      <HStack>
                        <Icon as={CalendarIcon} color="red.500" />
                        <Text>New Date Column</Text>
                      </HStack>
                    </MenuItem>
                  </MenuList>
                 </Menu>
                 </Flex>
               </FormControl>
              ))}
            </VStack>
          )}

          {step === 2 && (
            <VStack spacing={4} align="center">
              <Icon as={FaCheckCircle} color="green.500" boxSize={16} />
              <Text fontSize="xl" fontWeight="bold">
                Import Successful
              </Text>
              <Text>
                {importedRowCount} rows have been imported into your Zone table.
              </Text>
            </VStack>
          )}

          <HStack mt="4" justifyContent="space-between">
            {step > 0 && (
              <Button
                onClick={() => {
                  setStep(step - 1);
                  setActiveStep(activeStep - 1);
                }}
                variant="outline"
                fontWeight="normal"
                colorScheme="black"
                 color="gray.700"
                _hover={{ bg: 'gray.200' }}
              >
                Previous
              </Button>
            )}
            {step < 2 && (
              <Button
                colorScheme="orange"
                fontWeight="normal"
                onClick={() => {
                  if (step === 1) {
                    handleImport();
                  } else {
                    setStep(step + 1);
                    setActiveStep(activeStep + 1);
                  }
                }}
                isDisabled={step === 0 && !file}
              >
                {step === 1 ? 'Import' : 'Next'}
              </Button>
            )}
            {step === 2 && (
              <Button colorScheme="orange" onClick={() => {
                resetWizard();
                onClose();
              }}>
                Close
              </Button>
            )}
          </HStack>
        </Box>
      </VStack>
    </CustomPopup>
  );
};

export default ImportWizard;