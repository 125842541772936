import { Tr, Td } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import React, { memo } from 'react';
import { isEqual } from 'lodash';

interface TableRowProps {
  row: any; // Replace `any` with your actual type for row
  searchTerm: string;
  borderColor: string;
}

const TableRow: React.FC<TableRowProps> = ({ row, searchTerm, borderColor }) => {
  return (
    // <Tr key={`row-${row.id}-${row.index}-${searchTerm}`} className="table-row-fade">
    <Tr className="table-row-fade">
      {row.getVisibleCells().map((cell: any) => (
        <Td
          key={`cell-${cell.id}-${searchTerm}`}
          borderColor={borderColor}
          borderWidth="1px"
          borderStyle="solid"
          padding="3px 6px"
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Td>
      ))}
    </Tr>
  );
};

// Custom memoization with deep equality check using lodash's isEqual
export default TableRow;
